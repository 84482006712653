import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";

// import imgPhone from "../../assets/image/png/l7-mobile-2.png";
import screen3 from "../../assets/image/png/screen3.png";
// import screen4 from "../../assets/image/png/screen4.png";

const SectionStyled = styled(Section)``;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 49px;
    width: 49px;
    height: 49px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 3, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content3 = () => {
  return (
    <>
      {/* <!-- Content3 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img src={screen3} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>
                  Events, Videos....
                </Title>
                <Text>
                  We partner with AddisTips.com, the leading Ethiopians & Eritreans Events aggregating app, to provide their North America event listings on Kesto.
                </Text>
                <Text>
                  Post ads or find ads easily. Ads are organized so you can find what you’re looking for easily.
                </Text>
                {/* <WidgetContainer mt={5}>
                  <Widget title="Details, Directions" mb={4} num={5}>
                    Description, contact numbers, driving directions are available within 1-2 clicks
                  </Widget>
                  <Widget title="Share it anywhere" mb={4} num={6}>
                    You can share the local business info via fb/IG/email/SMS/Whatsapp/Viber etc - no problem.
                  </Widget>
                </WidgetContainer> */}
                {/* <Box mt={4}>
                  <Button>Get this app</Button>
                </Box> */}
              </div>
            </Col>
          </Row>

          {/* <Row className="align-items-center">
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>
                  Classified Ads
                </Title>
                <Text>
                  Post ads or find ads easily. Ads are organized so you can find what you’re looking for easily.
                </Text>
              </div>
            </Col>
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img src={screen4} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row> */}
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content3;
