import React from "react";
import Hero from "../sections/landing1/Hero";
import Clients from "../sections/landing1/Clients";
import Feature from "../sections/landing1/Feature";
import Content0 from "../sections/landing1/Content0";
import Content1 from "../sections/landing1/Content1";
import Content3 from "../sections/landing1/Content3";
// import Content4 from "../sections/landing1/Content4";
// import Testimonial from "../sections/landing1/Testimonial";
import CTA from "../sections/landing1/CTA";
import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        {/* <Clients /> */}
        {/* <Feature /> */}
        {/* <Content0 /> */}
        {/* <Content1 /> */}
        {/* <Content3 /> */}
        {/* <Content4 /> */}
        {/* <Testimonial /> */}
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
