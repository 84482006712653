import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
} from "../../components/Core";

import { device } from "../../utils";

import imgHero from "../../assets/image/png/l7-hero-image.png";
// import imgScreen1 from "../../assets/image/png/screen1.png";
import imgScreen1 from "../../assets/image/png/kesto_front_screenshot1.png";


const ImgRight = styled(Box)`
  @media ${device.sm} {
  }
`;

const SectionStyled = styled(Section)``;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 40px;
  position: relative;
  max-width: 411px;
  &:before {
    // counter-increment: widget-counter;
    content: '';
    position: absolute;
    left: 0;
    min-width: 16px;
    width: 16px;
    height: 16px;
    margin-top: 4px; 
    background-color: ${({ theme }) => '#ef6d00'};
    border-radius: 500px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: ${({ theme }) => theme.colors.light};
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      {/* <Text variant="small">{children}</Text> */}
    </WidgetStyled>
  );
};

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="#FCFDFE" className="position-relative" pb="60px !important">
        <div className="pt-5"></div>
        <Container>
          <Row className="align-items-center position-relative">
            <Col
              md="5"
              xl="4"
              className="position-static pb-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgRight>
                <img src={imgScreen1} alt="" className="img-fluid" />
              </ImgRight>
            </Col>
            <Col md="7" xl="6" className="offset-xl-1">
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >

              <Box>
                <Col lg="12" md={12} className="order-lg-1">
                  <div>
                    <Title variant="heroCustom">
                      Social+ app for Ethiopians & Eritreans
                    </Title>
                      <Text>
			  Kesto is built for Ethiopian & Eritrean community worldwide, packing many functionalities into a fast, convenient, and user-friendly app. It organizes its content in the form of channels making information easily accessible by users, businesses, and professionals.
                    </Text>
                    <WidgetContainer mt={5}>
                      <Widget title="Designed for mobile users" mb={4}>
                      </Widget>
                      <Widget title="Multiple data source, updated daily" mb={4}>
                      </Widget>
                      <Widget title="Multiple cities" mb={4}>
                      </Widget>
                      <Widget title="Details with Map" mb={4}>
                      </Widget>
                      <Widget title="Personalized" mb={4}>
                      </Widget>
                      <Widget title="Bookmark & Share" mb={4}>
                      </Widget>
                    </WidgetContainer>
                  </div>
                </Col>
              </Box>

                {/* <Box>
                  <Title>
                    Ethiopians & Eritreans "Yelp"
                  </Title>
                  <Box mb={4}>
                    <Text color="dark">
                      Kesto connects Ethiopians & Eritreans community with nearby local businesses. For business we make them easily discoverable, for consumers we simplify finding business easy.
                    </Text>
                  </Box>

                </Box> */}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
