import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgPhone from "../../assets/image/png/l7-mobile.png";

const SectionStyled = styled(Section)``;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 49px;
    width: 49px;
    height: 49px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content1 = () => {
  return (
    <>
      {/* <!-- Content1 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img src={imgPhone} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-2">
              <div>
                <Title>
                    Ethiopians & Eritreans "Yelp"
                </Title>
                <Text>
                    Kesto connects Ethiopians & Eritreans community with nearby local businesses. For business we make them easily discoverable, for consumers we simplify finding business easy.
                </Text>
                <WidgetContainer mt={5}>
                    <Widget title="Mobile-first & Fast" mb={4}>
                    Kesto is designed and optimized to work on any smart-phone, iOS or Android. We use latest web technologies and platforms to make accessing the information fast.
                    </Widget>
                    <Widget title="No download needed" mb={4}>
                    Kesto is a Web app, so you don’t have to worry about issues with app downloads, versions etc. Simply bookmark Kesto and it will appear just like a mobile app on your phone screen. Data is refreshed automatically & regularly so you have latest information.
                    </Widget>
                    
                </WidgetContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content1;
