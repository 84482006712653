import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button} from "../../components/Core";
import imgPlay from "../../assets/image/png/app-store.png";
import imgAppStore from "../../assets/image/png/google-play.png";
import imgWebApp from "../../assets/image/png/web-app.png";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const ButtonContainer = styled(Box)`
  margin-top: 35px;
  display: flex;
  justify-content: center;
`;

const ButtonApp = styled.a`
  padding: 0 9px;
  transition: 0.4s;
  &:visited {
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-8px);
  }
`;

const CTA = () => (
  <>
    <SectionStyled className="position-relative">
      <Container>
        {/* <Row className="justify-content-center" >
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title mb={3}>Download App</Title>
              
              <ButtonContainer>
                <ButtonApp href="https://app.kesto.io" target="_blank">
                  <Button width="100%" type="button" borderRadius={10}>Visit Kesto web app</Button>
                </ButtonApp>
              </ButtonContainer>
            </div>
          </Col>
        </Row> */}

        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title>Download App</Title>
              
              <ButtonContainer>
                <ButtonApp href="https://apps.apple.com/us/app/kesto/id1549147314" target="_blank">
                  <img src={imgPlay} alt="" className="img-fluid" />
                </ButtonApp>
                <ButtonApp href="https://play.google.com/store/apps/details?id=com.jotika.kesto" target="_blank">
                  <img src={imgAppStore} alt="" className="img-fluid" />
                </ButtonApp>
                {/* <ButtonApp href="https://app.kesto.io" target="_blank">
                  <img src={imgWebApp} alt="" className="img-fluid" />
                </ButtonApp> */}
              </ButtonContainer>
            </div>
          </Col>
        </Row>

      </Container>
    </SectionStyled>
  </>
);

export default CTA;
